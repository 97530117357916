.page-header {
    display: flex;
    align-items: flex-end;
    gap: @margin-xl;
    margin-bottom: @margin-xl;

    &__back {
        color: @gray-7;
        font-size: @heading-4-size;
        margin-right: -@margin-lg;
        &:hover {
            color: @text-color;
        }
    }

    &__title {
        margin: 0;
        font-size: @heading-1-size;
    }

    &__actions {
        margin-left: auto;
        display: flex;
        gap: @margin-md;
    }
}