[nz-row] {
    margin: calc( (@margin-md / 2) * -1);
    > .ant-col {
        padding: calc(@padding-md / 2);
    }

    &.no-gutter {
        margin: 0;
        .ant-col {
            padding: 0;
        }
    }
}