.ant-upload {
    &.ant-upload-drag {
        min-height: 95px;
        display: flex;
        background-color: @blue-1;
        border-color: @blue-4;

        &:not(.ant-upload-disabled)&:hover {
            background-color: @primary-1;
            border-color: @primary-4;
            .ant-upload-drag-container {
                color: @primary-6;
            }
        }
        
        .ant-upload-btn {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ant-upload-drag-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: @margin-md;
            color: @blue-6;
        }
    }
}