.description-inline {
    margin: 0 0 @margin-xxl;
    padding: 0;
    list-style-type: none;

    &__item {
        padding: @padding-xss 0;
        border-bottom: 1px solid @border-color-split;
        display: flex;
        gap: @margin-xs;
    }

    &__label {
        flex: 0 0 200px;
        font-weight: 700;
        text-align: right;
        color: @gray-8;
        &::after {
            content: ':'
        }
    }
}