.mt-auto { margin-top: auto !important; }
.me-auto { margin-right: auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ms-auto { margin-left: auto !important; }

.m-0 { margin: 0 !important; }
.mt-0 { margin-top: 0 !important; }
.me-0 { margin-right: 0 !important; }
.mb-0 { margin-bottom: 0 !important; }
.ms-0 { margin-left: 0 !important; }

.p-0 { padding: 0 !important; }
.pt-0 { padding-top: 0 !important; }
.pe-0 { padding-right: 0 !important; }
.pb-0 { padding-bottom: 0 !important; }
.ps-0 { padding-left: 0 !important; }


// Auto generate classes such as
// ex: .mt-md { margin-top: 16px; }
.generate-spacers(@key, @value) {
    .mt-@{key} { margin-top: @value !important; }
    .me-@{key} { margin-right: @value !important; }
    .mb-@{key} { margin-bottom: @value !important; }
    .ms-@{key} { margin-left: @value !important; }

    .pt-@{key} { padding-top: @value !important; }
    .pe-@{key} { padding-right: @value !important; }
    .pb-@{key} { padding-bottom: @value !important; }
    .ps-@{key} { padding-left: @value !important; }
}

each(@spacers, { .generate-spacers(@key, @value) })