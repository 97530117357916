.ant-tag {
    min-height: 26px;
    display: inline-flex;
    align-items: center;
    gap: @margin-xss;
    padding: @padding-xss @padding-xs;
    white-space: wrap;
    margin: 0;
    line-height: 1.24;

    &.editable-tag {
        background-color: @primary-1;
        color: @primary-7;
        border-color: @primary-7;
        border-style: dashed;
        cursor: pointer;
    }

    &.primary-tag {
        background-color: @primary-1;
        color: @primary-7;
        border-color: @primary-7;
    }
}

.editable-tag-input {
    height: 26px;
    width: 108px;
    font-family: @font-size-sm;
}

.tags-group {
    display: flex;
    flex-wrap: wrap;
    gap: @margin-xs;
}