.tab-container {
    background-color: @component-background;
    border: 1px solid @border-color-split;
    border-radius: @border-radius-base;
    overflow: hidden;

    .ant-tabs {
        &.ant-tabs-top {
            > .ant-tabs-nav {
                background-color: @gray-2;
                margin: 0;
                .ant-tabs-tab {
                    border-radius: 0;
                    border: 0;
                    margin: 0;
                    background-color: transparent;
                    cursor: pointer;
                    color: @gray-8;

                    &:hover {
                        background-color: @gray-1;
                        color: @text-color;
                    }

                    button {
                        cursor: pointer;
                    }

                    + .ant-tabs-tab {
                        margin: 0;
                    }

                    &.ant-tabs-tab-active {
                        background-color: @component-background;
                        button {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    
    .ant-tabs-content-holder {
        padding: @card-padding-base;
    }
}

