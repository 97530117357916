.ant-table-thead {
    > tr {
        > th {
            font-weight: 700;
        }
    }
}

.table-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: @margin-xs;
}

.ant-table {
    .cdk-drag-placeholder {
        background-color: @primary-1;
        cursor: move;
        td {
            position: relative;
            border: 0 !important;
            text-indent: -9999px;
            > * {
                opacity: 0;
            }
            &:before {
                content: '';
                border-top: 1px dashed @primary-3 !important;
                border-bottom: 1px dashed @primary-3 !important;
                position: absolute;
                inset: -1px;
                z-index: 1;
            }
            &:first-child::before {
                border-left: 1px dashed @primary-3 !important;
            }
            &:last-child::before {
                border-right: 1px dashed @primary-3 !important;
            }
        }
    }

    .cdk-drag-handle {
        cursor: ns-resize;
        transition: all @animation-duration-base ease;
    }

    .cdk-drag-disabled {
        .cdk-drag-handle {
            opacity: 0.24;
            cursor: not-allowed;
        }
    }
}

.cdk-drag-preview {
     &.ant-table-row {
         background-color: rgba(@component-background, 0.62);
         padding: @table-padding-vertical @table-padding-horizontal;
     }
}