// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "ng-zorro-antd/ng-zorro-antd.less";
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

/* Importing fontawesome icons. */
@import '@fortawesome/fontawesome-pro/css/all.min.css';

// Variables
@import 'variables/variables';

// Core
@import 'core/global';
@import 'core/spacers';
@import 'core/typography';

// Components
@import 'components/button';
@import 'components/grid';
@import 'components/form';
@import 'components/form-section';
@import 'components/alert';
@import 'components/table';
@import 'components/select';
@import 'components/dropdown';
@import 'components/popover';
@import 'components/upload';
@import 'components/aside';
@import 'components/card';
@import 'components/tag';
@import 'components/calendar';
@import 'components/tabs';
@import 'components/page-header';
@import 'components/code-input';
@import 'components/description-inline';
@import 'components/notes';