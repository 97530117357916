code-input {
    // margin-left: -@margin-xss !important;
    // margin-right: -@margin-xss !important;
    display: flex;
    gap: @margin-sm;
    > * {
        padding: 0 !important;
    }

    input {
        height: @height-lg * 1.35 !important;
        font-size: @heading-3-size !important;
        border-radius: @border-radius-base !important;
        border-color: @border-color-split !important;
        &:focus {
            border-color: @primary-color !important;
            box-shadow: 0 0 @outline-blur-size @outline-width rgba(@primary-color, @outline-fade) !important;
        }
    }
}