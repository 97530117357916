.ant-btn {
    font-family: @font-family;

    [class^="fa-"] {
        + span {
            margin-left: @spacers[xs];
        }
    }

    span {
        + [class^="fa-"] {
            margin-left: @spacers[xs];
        }
    }

    &:not(.ant-btn-background-ghost):not(.ant-btn-dashed) {
        border: 0;
    }

    &.ant-btn-background-ghost:not(.ant-btn-primary),
    &.ant-btn-dashed:not(.ant-btn-primary) {
        color: @btn-default-color;
        border-color: @btn-default-border; 
    }

    &:not(.ant-btn-primary):not(.ant-btn-dangerous):hover {
        background-color: @gray-3;
        color: @text-color;
    }

    &.ant-btn-primary {
        &:hover,
        &:focus,
        &.ant-btn-background-ghost:hover,
        &.ant-btn-background-ghost:focus {
            background: @primary-color-hover;
            border-color: $background;
            color: @white;
        }
        &:active,
        &.active,
        .ant-btn-background-ghost:active {
            background: @primary-color-active;
            border-color: $background;
            color: @white;
        }
    
        &:disabled,
        &[disabled] {
            background: @primary-3;
            border-color: $background;
            color: @white;
            &:hover {
                background: @primary-3;
                border-color: $background;
                color: @white;
            }
        }

        &.secondary {
            border-color: @secondary-color;
            background: @secondary-color;
            color: @text-color;
    
            &:hover,
            &:focus,
            &.ant-btn-background-ghost:hover,
            &.ant-btn-background-ghost:focus {
                background: @secondary-color-hover;
                border-color: $background;
                color: @text-color;
            }
            &:active,
            &.active,
            &.ant-btn-background-ghost:active {
                background: @secondary-color-active;
                border-color: $background;
                color: @text-color;
            }
    
            &:disabled,
            &[disabled] {
                background: tint(@secondary-color, 48%);
                border-color: $background;
                color: @text-color-secondary;
                &:hover {
                    background: tint(@secondary-color, 48%);
                    border-color: $background;
                    color: @text-color-secondary;
                }
            }
    
            &.ant-btn-background-ghost {
                background: transparent;
                color: @text-color;
    
                &:disabled,
                &[disabled] {
                    background: tint(@secondary-color, 94%);
                    border-color: tint(@secondary-color, 48%);
                    color: @text-color-secondary;
                    &:hover {
                        background: tint(@secondary-color, 94%);
                        border-color: tint(@secondary-color, 48%);
                        color: @text-color-secondary;
                    }
                }
            }
        }

        &.success {
            border-color: @success-color;
            background: @success-color;
    
            &:hover,
            &:focus,
            &.ant-btn-background-ghost:hover,
            &.ant-btn-background-ghost:focus {
                background: @success-color-hover;
                border-color: $background;
                color: @white;
            }
            &:active,
            &.active,
            &.ant-btn-background-ghost:active {
                background: @success-color-active;
                border-color: $background;
                color: @white;
            }
    
            &:disabled,
            &[disabled] {
                background: tint(@success-color, 48%);
                border-color: $background;
                color: @white;
                &:hover {
                    background: tint(@success-color, 48%);
                    border-color: $background;
                    color: @white;
                }
            }
    
            &.ant-btn-background-ghost {
                background: transparent;
                color: @success-color;
    
                &:disabled,
                &[disabled] {
                    background: tint(@success-color, 94%);
                    border-color: tint(@success-color, 48%);
                    color: $border-color;
                    &:hover {
                        background: tint(@success-color, 94%);
                        border-color: tint(@success-color, 48%);
                        color: $border-color;
                    }
                }
            }
        }

        &.warning {
            border-color: @warning-color;
            background: @warning-color;
    
            &:hover,
            &:focus,
            &.ant-btn-background-ghost:hover,
            &.ant-btn-background-ghost:focus {
                background: @warning-color-hover;
                border-color: $background;
                color: @white;
            }

            &:active,
            &.active,
            &.ant-btn-background-ghost:active {
                background: @warning-color-active;
                border-color: $background;
                color: @white;
            }
    
            &:disabled,
            &[disabled] {
                background: tint(@warning-color, 48%);
                border-color: $background;
                color: @white;
                &:hover {
                    background: tint(@warning-color, 48%);
                    border-color: $background;
                    color: @white;
                }
            }
    
            &.ant-btn-background-ghost {
                background: transparent;
                color: @warning-color;
    
                &:disabled,
                &[disabled] {
                    background: tint(@warning-color, 94%);
                    border-color: tint(@warning-color, 48%);
                    color: $border-color;
                    &:hover {
                        background: tint(@warning-color, 94%);
                        border-color: tint(@warning-color, 48%);
                        color: $border-color;
                    }
                }
            }
        }

        &.white {
            border-color: @white;
            background: @white;
            color: @text-color;
    
            &:hover,
            &:focus,
            &.ant-btn-background-ghost:hover,
            &.ant-btn-background-ghost:focus {
                background: @gray-1;
                border-color: $background;
                color: @text-color;
            }

            &:active,
            &.active,
            &.ant-btn-background-ghost:active {
                background: @gray-2;
                border-color: $background;
                color: @text-color;
            }
    
            &:disabled,
            &[disabled] {
                background: @white;
                border-color: $background;
                color: @text-color;
                opacity: 36%;
                &:hover {
                    background: @white;
                    border-color: $background;
                    color: @text-color;
                    opacity: 36%;
                }
            }
    
            &.ant-btn-background-ghost {
                background: transparent;
                color: @warning-color;
    
                &:disabled,
                &[disabled] {
                    background: tint(@warning-color, 94%);
                    border-color: tint(@warning-color, 48%);
                    color: $border-color;
                    &:hover {
                        background: tint(@warning-color, 94%);
                        border-color: tint(@warning-color, 48%);
                        color: $border-color;
                    }
                }
            }
        }
        
        &.error,
        &.ant-btn-dangerous {
            border-color: @error-color;
            background: @error-color;
    
            &:hover,
            &:focus,
            &.ant-btn-background-ghost:hover,
            &.ant-btn-background-ghost:focus {
                background: @error-color-hover;
                border-color: $background;
                color: @white;
            }

            &:active,
            &.active,
            &.ant-btn-background-ghost:active {
                background: @error-color-active;
                border-color: $background;
                color: @white;
            }
    
            &:disabled,
            &[disabled] {
                background: tint(@error-color, 48%);
                border-color: $background;
                color: @white;
                &:hover {
                    background: tint(@error-color, 48%);
                    border-color: $background;
                    color: @white;
                }
            }
    
            &.ant-btn-background-ghost {
                background: transparent;
                color: @error-color;
    
                &:disabled,
                &[disabled] {
                    background: tint(@error-color, 94%);
                    border-color: tint(@error-color, 48%);
                    color: $border-color;
                    &:hover {
                        background: tint(@error-color, 94%);
                        border-color: tint(@error-color, 48%);
                        color: $border-color;
                    }
                }
            }
        }
    }

    &.ant-btn-text {
        &:not(.ant-btn-dangerous):hover,
        &:not(.ant-btn-dangerous):focus,
        &:not(.ant-btn-dangerous):active {
            color: @text-color;
            background-color: @gray-1;
        }
        &.primary {
            color: @primary-color;
            &:hover,
            &:focus,
            &:active {
                color: @primary-color;
                background-color: @blue-1;
            }

            &.active {
                background-color: @primary-color;
                color: @white;
            }
        }

        &.secondary {
            color: @text-color;
            &:hover,
            &:focus,
            &:active {
                color: @text-color;
                background-color: @yellow-1;
            }

            &.active {
                color: @text-color;
                background-color: @secondary-color;
            }
        }

        &.success {
            color: @success-color;
            &:hover,
            &:focus,
            &:active {
                color: @success-color;
                background-color: @green-1;
            }

            &.active {
                color: @white;
                background-color: @success-color;
            }
        }

        &.warning {
            color: @orange-4;
            &:hover,
            &:focus,
            &:active {
                color: @orange-4;
                background-color: @orange-1;
            }

            &.active {
                color: @white;
                background-color: @warning-color;
            }
        }

        &.error {
            color: @error-color;
            &:hover,
            &:focus,
            &:active {
                color: @error-color;
                background-color: @red-1;
            }

            &.active {
                color: @white;
                background-color: @error-color;
            }
        }
    }
}


