h1, h2, h3, h4, h5, h6 {
    font-family: @heading-font-family;
    color: @heading-color;
    font-weight: 700;
    margin: 0;
    line-height: 1;
}

h1 { font-size: @heading-1-size; margin-bottom: @margin-xl; font-weight: 400; }
h2 { font-size: @heading-2-size; margin-bottom: @margin-sm; font-weight: 400; }
h3 { font-size: @heading-3-size; margin-bottom: @margin-sm; font-weight: 400; }
h4 { font-size: @heading-4-size; margin-bottom: @margin-sm; font-weight: 400; }
h5 { font-size: @heading-5-size; margin-bottom: @margin-xs; font-weight: 700; }
h6 { font-size: @heading-6-size; margin-bottom: @margin-xss; font-weight: 700; }

.text-regular   { font-weight: 400 !important; }
.text-bold      { font-weight: 700 !important; }
.text-normal    { font-style: normal !important; }
.text-italic    { font-style: italic !important; }
.text-left      { text-align: left !important; }
.text-center    { text-align: center !important; }
.text-right     { text-align: right !important; }

p {
    margin: 0;
    + p {
        margin-top: @margin-lg;
    }
}