.form-section {
    margin: @margin-xl -@margin-md;
    padding: 0 @padding-md @padding-xl;
    border-bottom: 1px solid @border-color-split;


    > .ant-row {
        margin-bottom: @margin-sm;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        margin-bottom: @margin-xs;
        font-size: @heading-5-size;
        font-weight: 700;
    }

    .ant-checkbox-wrapper {
        min-height: @height-base;
        display: flex;
        align-items: center;

        .ant-checkbox {
            margin-top: -@margin-xss;
        }
    }
}