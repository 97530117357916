.ant-alert {
    &.ant-alert-info {
        .ant-alert-message      { color: @cyan-7; }
        .ant-alert-description  { color: @cyan-9; }
    }

    &.ant-alert-success {
        .ant-alert-message      { color: @green-7; }
        .ant-alert-description  { color: @green-9; }
    }

    &.ant-alert-warning {
        .ant-alert-message      { color: @orange-7; }
        .ant-alert-description  { color: @orange-9; }
    }

    &.ant-alert-error {
        .ant-alert-message      { color: @red-7; }
        .ant-alert-description  { color: @red-9; }
    }
}
