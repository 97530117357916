.ant-color-picker-trigger:hover {
    border-color: @primary-5;
}

.ant-input-affix-wrapper {
    color: @input-affix-color;
}

.ant-select-arrow {
    &::before {
        content: '\f107';
        font-family: 'Font Awesome 6 Pro';
        font-size: @font-size-md;
        display: block;
        color: @input-affix-color;
    }
    .anticon {
        display: none;
    }
}

label:not(.ant-checkbox-wrapper):not(.ant-radio-wrapper) {
    font-weight: 700;
}

.ant-form-item {
    &:last-child {
        margin-bottom: 0;
    }
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin: 0.5rem 0;
}

.mouse-pointer {
    cursor: pointer;
}

.attendancy-red {
    .ant-radio-checked .ant-radio-inner {
        border-color: #ff4d4d !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
        background-color: #ff4d4d;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: #ff4d4d;
    }
}

.yellow-button {
    .ant-checkbox-inner,
    .ant-checkbox-input {
        transform: scale(2);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: @yellow-base;
        border-color: @yellow-base;
    }
    .ant-checkbox {
        margin-right: 5px;
    }
}
