.notes-list {
    .note-item {
        padding: @padding-sm 0;
        border-top: 1px solid @border-color-split;
    }

    .note-item-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: @margin-sm;

        .note-item-header__title {
            flex: 1 1 auto;
            color: @text-color-secondary;
            font-weight: 700;
        }

        .note-item-header__actions {
            flex: 0 0 auto;
            display: flex;
            gap: @margin-xss;
        }
    }

    .note-item-body {
        margin-top: @margin-xs;
    }

    .note-item-edit {
        background-color: @gray-1;
        padding: @padding-xs;
        font-size: @font-size-sm;
        margin-top: @margin-xss;
        border-radius: @border-radius-sm;
        &__header {
            font-weight: 700;
            color: @text-color-secondary;
        }
        &__body {
            font-style: italic;
        }
    }
}