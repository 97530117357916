@import "colors";

@font-family: 'PT Sans', system-ui, -apple-system, "Segoe UI", sans-serif;

@topbar-height: 54px; // Custom, not used by NG-Zorro
@sidebar-width: 240px; // Custom, not used by NG-Zorro

@primary-color: @primary-6;
@primary-color-hover: @primary-7;
@primary-color-active: @primary-7;
@primary-color-outline: fade(@primary-color, @outline-fade);

@secondary-color: @secondary-6;
@secondary-color-hover: @secondary-7;
@secondary-color-active: @secondary-7;
@secondary-color-outline: fade(@secondary-color, @outline-fade);

@success-color: @green-6;
@success-color-hover: @green-7;
@success-color-active: @green-7;
@success-color-outline: fade(@success-color, @outline-fade);

@warning-color: @orange-6;
@warning-color-hover: @orange-7;
@warning-color-active: @orange-7;
@warning-color-outline: fade(@warning-color, @outline-fade);

@error-color: @red-6;
@error-color-hover: @red-7;
@error-color-active: @red-7;
@error-color-outline: fade(@error-color, @outline-fade);

@info-color: @cyan-6;
@info-color-deprecated-bg: @cyan-7;
@info-color-deprecated-border: @cyan-8;
@info-color-outline: fade(@info-color, @outline-fade);

@body-background: @gray-1;
@component-background: @white;
@border-color-base: @gray-4;
@border-color-split: @gray-3;
@text-selection-bg: @gray-7;

@item-active-bg: @primary-color;
@item-hover-bg: @gray-2;

@font-size-base: 14px;
@font-size-xs: 10px;
@font-size-sm: 12px;
@font-size-md: @font-size-base; // Custom, not used by NG-Zorro
@font-size-lg: 16px;
@font-size-xl: 18px;
@text-color: @gray-10;
@text-color-secondary: @gray-6;
@heading-font-family: @font-family;
@heading-color: @primary-8;
@heading-1-size: 28px;
@heading-2-size: 24px;
@heading-3-size: 20px;
@heading-4-size: 18px;
@heading-5-size: 16px;
@heading-6-size: 14px;

@border-radius-base: 4px;
@border-radius-sm: 2px;

@btn-font-weight: 700;
@btn-border-width: 1px;
@btn-shadow: none;
@btn-primary-shadow: none;
@btn-text-shadow: none;

@btn-default-color: @text-color;
@btn-default-bg: @gray-1;
@btn-default-border: @gray-7;

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

// Link
@link-color: @cyan-6;
@link-hover-color: @cyan-7;
@link-active-color: @cyan-7;
@link-decoration: none;
@link-hover-decoration: none;
@link-focus-decoration: none;
@link-focus-outline: 0;

// Height
@height-base: 34px;
@height-lg: 40px;
@height-sm: 28px;

// Spacers
@spacer: 14px;
@spacers: {
    xss: 4px;
    xs: 8px;
    sm: 12px;
    md: 14px;
    lg: 18px;
    xl: 24px;
    xxl: 32px;
}

// Paddings
@padding-xss: @spacers[xss]; // more small
@padding-xs: @spacers[xs]; // small items
@padding-sm: @spacers[sm]; // Form controls and items
@padding-md: @spacer; // small containers and buttons
@padding-lg: @spacers[lg]; // containers
@padding-xl: @spacers[xl]; // Unused by NG-Zorro
@padding-xxl: @spacers[xxl]; // Unused by NG-Zorro

// Padding for all form controls
@control-padding-horizontal: @padding-sm;
@control-padding-horizontal-sm: @padding-xs;

// Margins
@margin-xss: @spacers[xss]; // more small
@margin-xs: @spacers[xs]; // small items
@margin-sm: @spacers[sm]; // Form controls and items
@margin-md: @spacer; // small containers and buttons
@margin-lg: @spacers[lg]; // containers
@margin-xl: @spacers[xl]; // Unused by NG-Zorro
@margin-xxl: @spacers[xxl]; // Unused by NG-Zorro

// Padding for all form controls
@control-padding-horizontal: @padding-xs;
@control-padding-horizontal-sm: @padding-xs;

// Outline
@outline-blur-size: 8px;
@outline-width: 1px;
@outline-color: @primary-color; // No use anymoe
@outline-fade: 12%;

// Form
@form-item-trailing-colon: false;
@form-item-label-height: auto;
@label-color: @text-color;
@form-vertical-label-padding: 0 0 @padding-xss;
@form-item-margin-bottom: @margin-xl;

// input
@input-padding-horizontal: @control-padding-horizontal;
@input-placeholder-color: @gray-4;
@input-icon-color: @primary-color;
@input-affix-color: @text-color-secondary; // Unused by NG-Zorro
@input-number-handler-hover-bg: @primary-5;
@input-hover-border-color: @primary-5;

// Select
@select-item-selected-bg: @item-active-bg;
@select-item-active-bg: @item-hover-bg;
@select-item-selected-font-weight: 400;

// Drawer
@drawer-header-padding: @padding-sm @padding-lg;

// Card
@card-head-padding: @padding-xs;
@card-head-padding-sm: @padding-xss;
@card-padding-base: @padding-md;
@card-padding-base-sm: @padding-sm;
@card-actions-li-margin: @padding-sm 0;

// Table
@table-bg: @component-background;
@table-header-bg: @gray-2;
@table-header-color: @gray-8;
@table-footer-bg: @gray-2;
@table-footer-color: @gray-8;
@table-padding-vertical: @padding-xs;
@table-padding-horizontal: @padding-sm;
@table-padding-vertical-md: (@table-padding-vertical * 3 / 4);
@table-padding-horizontal-md: (@table-padding-horizontal / 2);
@table-padding-vertical-sm: (@table-padding-vertical / 2);
@table-padding-horizontal-sm: (@table-padding-horizontal / 2);
@table-font-size: @font-size-base;
@table-font-size-md: @table-font-size;
@table-font-size-sm: @font-size-sm;
@table-header-cell-split-color: rgba(@black, 0.12);

// Alert
@alert-success-border-color: @green-2;
@alert-success-bg-color: @green-1;
@alert-success-icon-color: @success-color;

@alert-info-border-color: @cyan-2;
@alert-info-bg-color: @cyan-1;
@alert-info-icon-color: @info-color;

@alert-warning-border-color: @orange-2;
@alert-warning-bg-color: @orange-1;
@alert-warning-icon-color: @warning-color;

@alert-error-border-color: @red-2;
@alert-error-bg-color: @red-1;
@alert-error-icon-color: @error-color;

// Tag
@tag-border-radius: @border-radius-sm;
@tag-default-bg: @gray-1;
@tag-default-color: @gray-8;
@tag-font-size: @font-size-sm;

// Calendar
@calendar-column-active-bg: @primary-1;

// Checkbox
@checkbox-color: @primary-color;

// Radio
@radio-dot-color: @primary-color;
@radio-button-hover-color: @primary-5;
@radio-button-active-color: @primary-7;

// Tabs
@tabs-card-active-color: @primary-color;
@tabs-ink-bar-color: @primary-color;
@tabs-highlight-color: @primary-color;
@tabs-hover-color: @primary-5;
@tabs-active-color: @primary-7;