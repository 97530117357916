body {
    font-family: @font-family;
    font-size: @font-size-base;
    color: @text-color;
    overscroll-behavior-y: contain;
}

.container {
    margin: 0;
    padding: 0 @spacer;
}

img {
    max-width: 100%;
}

.w-100 {
    width: 100%;
}

.mb-15 {
    margin-bottom: 15px!important;
}

.column-reverse {
    display: flex; 
    flex-direction: column-reverse;
}

[app-disabled] {
    opacity: 0.96;
    pointer-events: none;
}

.justify-space-between {
    justify-content: space-between;
}